/* App.js Style */
@import './App.scss'; 
* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container{
  padding: 0 2%;
}
.grey-text{
margin-bottom: 0.5rem;
text-align: justify;
text-justify: inter-word;
/* text-justify: inter-character; */
}
.grey-bold{
 font-weight: 800;
}
.grey-bold-2{
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 0.3rem;
}
.mainWrapper{
    height:202vh;
    background-image: url("./images/all_saints_pre-school_hw_2016_17001012.png");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;

}
.imagesWrapper{
    display: flex;
    flex-wrap: nowrap;
}

 img.circle {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    max-width: 85%;
}
@media only screen and (max-width: 700px){
  .flex-container{
    flex-direction: column;
   
}
img.circle {
  max-width: 65%;
}
}

/* Home.js Style */

.homeWrapper {
    padding:20px 10px;
    /* margin: 15px; */

}

/* Earlyyears.js Style */

.list{
    list-style-type: circle;
    list-style-position: inside;
    list-style-type: decimal;
}
/* nav bar */
.navWrapper{
  width:100%;
}
.fixed-nav-top{
    position: fixed;
    top: 0;
    z-index: 100;
}
.navList{
  width: 100%;
  background-color: red!important ;
}
 @media only screen and (max-width: 1009px) {
  .navWrapper{
    text-align: center;
  }
  
}

/* Gallery Style */
.myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {opacity: 0.7;}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  padding-top: 0px; /* Location of the box */
  left: 50%;
  top: 50%;
  width: 100%; /* Full width */
  min-height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
  transform: translate(-50%, -50%)
}
.modal-content {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 700px;
  max-height: 500px;
  height: 100%;
 
}
/* Add Animation - Zoom in the Modal */
.modal-content, #caption { 
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.nav-active{
  background-color: #FEFEFE;
}
.dark-title, .dark-title-1{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 13px;
}
.dark-title-1{
  font-size: 22px;
  font-weight: 400;
}
.downloadablePolicyLinks{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
  .sm-col-bg{
    background-color: #fff;
  }
}
div.gallery {
    border: 1px solid #ccc;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery img.thumpNail{
    width: 100%;
    height: 30vh;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
  }
  @media only screen and (max-width: 700px) {
    .responsive {
      width: 49.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  /**term dates style**/
  .termDates-header{
    color: rgb(219, 58, 58);
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 700;
    font-family:Arial, Helvetica, sans-serif;
    /* display: inline-block; */
    /* border-bottom: solid 3px rgb(219, 58, 58); */
    padding-bottom: 3px; 
    margin: 10px 0px;
  }
  
  span:only-child{
    border: solid 0px white;
  }

  i{
    color:  rgb(219, 58, 58);;
  }
  @media only screen and (min-width: 100px){
    .container {
      width: 100%;
  }
  }
  @media only screen and (min-width: 601px){
    .container {
      width: 100%;
  }
  }
  
  @media only screen and (min-width: 993px){
    .container {
      width: 70%;
  }
  }
  @media all and (max-width: 405px){
    .downloadablePolicyLinks > button{
      font-size: 10px;
     }
  }