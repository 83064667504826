$primary-color: red;
h5{
    color: $primary-color !important;
}
i.tiny{
    margin-right: 0.5rem;
}

img:hover{
    background-color: #fce4ec;
}
.flex-container{
    display: flex;
    justify-content: center;
}