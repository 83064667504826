.nav_top {
  display: flex;
  justify-content: space-around;
  padding: 0 1rem;
}
.top_message {
  align-self: center;
  color: red;
  font-size: 2rem;
}
.alert_message {
  margin-top: 2.3rem;
  margin-bottom: 2.3rem;
}
.flex-nav {
  display: flex !important;
  justify-content: space-evenly;
}
@media only screen and (max-width: 993px) {
  .flex-nav {
    display: none !important;
  }
}
@media only screen and (max-width: 700px) {
  .top_message {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .nav_top {
    flex-direction: column;
    justify-content: space-around;
  }
  .nav-img {
    align-self: flex-start;
  }
}
@media only screen and (min-width: 911px) {
  nav li a.blue-text.text-lighten-2 {
    padding: 0 6px !important;
  }
}
